import './styles/index.css'
import createAppRoot from "./utils/createAppRoot";
import App from "./App";
import Modal from "./components/Modal/Modal";

const Lab = () => {
    return (
        <Modal
            type={"screen"}
            isOpen={true}
            title={"Gliesereum Claim"}
            subTitle={"FTX, SPACEFEX FINANCE"}
        >
            <App/>
        </Modal>
    )
}

createAppRoot(window).render(<Lab/>)

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stels-loader-full {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,OAAO;IACP,QAAQ;IACR,MAAM;IACN,SAAS;IACT,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;AACnB","sourcesContent":[".stels-loader-full {\n    display: flex;\n    position: fixed;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    margin: auto;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

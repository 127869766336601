import React, {FC} from "react";
import {motion} from "framer-motion";
import "./Loader.style.css"

interface LoaderProps {
  width?: number;
  msg?: string;
  repeat?: number;
  type?: "full" | string;
  color?: string;
}

const prefix = 'stels-loader-'

const Loader: FC<LoaderProps> = ({
                                   width = 59,
                                   msg,
                                   repeat = Infinity,
                                   type = "full",
                                   color = "var(--stels-color-green, orange)"
                                 }) => {
  return (
    <motion.div
      className={type === "full" ? prefix + "full" : prefix + "local"}
    >
      <motion.svg
        initial={{scale: 0.8}}
        animate={{
          rotate: 360,
        }}
        transition={{
          type: "spring",
          stiffness: 180,
          damping: 30,
          repeat: repeat,
          repeatType: "loop",
        }}
        viewBox="0 0 59 59"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        fill="none"
      >
        <motion.path
          fill="var(--stels-color-gray, gray)"
          d="M29.5 56.542a27.042 27.042 0 0 1 0-54.084 2.458 2.458 0 0 1 0 4.917 22.125 22.125 0 1 0 8.85 42.411 2.458 2.458 0 0 1 1.967 4.504A26.83 26.83 0 0 1 29.5 56.542Z"
        />
        <motion.path
          fill={color}
          d="M47.055 49.167a2.459 2.459 0 0 1-1.755-4.18 22.126 22.126 0 0 0-6.95-35.773 2.458 2.458 0 0 1 1.967-4.504 27.042 27.042 0 0 1 8.49 43.72 2.462 2.462 0 0 1-1.752.737Z"
        />
      </motion.svg>
      <div style={{marginTop: "1em"}}>
        <span>{msg}</span>
      </div>
    </motion.div>
  );
};

export default Loader;

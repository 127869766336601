import React, {useEffect, useState} from 'react';
import {isValidNumber} from 'libphonenumber-js';
import Button from "./components/Button/Button";
import Loader from "./components/Loader/Loader";
import { motion } from 'framer-motion';
import Graphite from "./components/Craphite/Graphite";

const URL = 'https://api.gliesereum.com/claim/request';

const App: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [valid, setValid] = useState(false);
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: ''
  });

  useEffect(() => {
    if (validationErrors.email || validationErrors.phone) {
      const timer = setTimeout(() => {
        setValidationErrors({});
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [validationErrors]);
  
  const validate = () => {
    const errors: { [key: string]: string } = {};
    const emailRegex = /@[a-zA-Z0-9.-]{2,}$/;
    //const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    // Make at least one of email or phone required
    if (!formData.email && !formData.phone) {
      errors.email = "Email/Telegram or Phone is required";
      errors.phone = "Email/Telegram or Phone is required";
    } else {
      if (formData.email && !emailRegex.test(formData.email)) {
        errors.email = "Invalid Email or Telegram";
      }
      
      if (formData.phone && !isValidNumber("+1" + formData.phone)) {
        errors.phone = "Invalid Phone";
      }
    }
    
    setValidationErrors(errors);
    
    return Object.keys(errors).length === 0;
  };
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    const emailRegex = /@[a-zA-Z0-9.-]{2,}$/;
    //setValid(emailRegex.test(value))
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validate()) return;
    setLoading(true);
    
    try {
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      setLoading(false);
      setResult(true);
      setError('');
    } catch (error) {
      setLoading(false);
      setResult(false);
      setError('Error form submit');
    }
  };

  if(result){
    return (
      <div className="huragan-form-success">
        <div>
          <h1>Thank for your claim request!</h1>
        </div>
        <div style={{width: 200}}>
          <Graphite size={6}/>
        </div>
        <div style={{marginTop: "1em"}}>
          <h1 style={{fontSize: 22}}>Gliesereum Ukraine</h1>
        </div>
        <div style={{textAlign: "center", padding: "2em", color: "var(--stels-color-gray)"}}>
          <p>Thank you! We'll be in touch with you shortly. In the meantime, why not indulge in our latest cinematic masterpiece crafted just for you? It's a high-octane adventure you won't want to miss!</p>
        </div>
      </div>
    )
  }
  
  return (
    <div className="huragan">
      {loading && (
        <div className="huragan-form-loading">
          <Loader msg="Sending ..." width={40}/>
        </div>
      )}
      {error && <div className="huragan-form-error">{error}</div>}
      <div className="huragan-message">
        {/*<div className="huragan-message-logo">
          <Graphite size={4}/>
        </div>*/}
        <div style={{textAlign: "center"}}>
          <h1 style={{fontSize: 22}}>
            Gliesereum Ukraine begins a capital return program for FTX and SPACEFEX FINANCE portfolios
          </h1>
          <span style={{color: "var(--stels-color-gray)", fontSize:12}}>
            The resumption of our company's operations is of utmost importance to us and our partners. We guarantee the return of all digital assets associated with agents based on signed capital return contracts with thorough verification.
          </span>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="huragan-form">
        <div className="huragan-form-item">
          <h1 style={{color: "var(--stels-color-green)", fontSize: 22}}>Claim Request</h1>
          <p style={{color: "var(--stels-color-gray)"}}>Wallet ID/Agent ID is required</p>
        </div>
        <div className="huragan-form-item">
          <label>
            <input
              type="text"
              name="name"
              placeholder={"Wallet ID"}
              value={formData.name}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="huragan-form-item">
          <label>
            <input
              type="text"
              name="email"
              placeholder={"Agent ID"}
              value={formData.email}
              onChange={handleChange}
            />
          </label>
          {validationErrors.email &&
              <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  className="huragan-error-message">
                {validationErrors.email}
              </motion.div>
          }
        </div>
        {/*<div className="huragan-form-item huragan-input-phone">*/}
        {/*  <div className="huragan-input-country">*/}
        {/*    <span>+</span>*/}
        {/*  </div>*/}
        {/*  <label>*/}
        {/*    <input*/}
        {/*      type="text"*/}
        {/*      name="phone"*/}
        {/*      placeholder={"1 276 7778899"}*/}
        {/*      value={formData.phone}*/}
        {/*      onChange={handleChange}*/}
        {/*    />*/}
        {/*  </label>*/}
        {/*  {validationErrors.phone &&*/}
        {/*      <motion.div*/}
        {/*          initial={{opacity: 0}}*/}
        {/*          animate={{opacity: 1}}*/}
        {/*          className="huragan-error-message">*/}
        {/*        {validationErrors.phone}*/}
        {/*      </motion.div>}*/}
        {/*</div>*/}
        <div className="huragan-form-item">
          <Button type="submit" disabled={!valid}>
            Request Return
          </Button>
        </div>
      </form>
      <footer style={{textAlign: "center", fontSize: 12, marginTop: "2em", color: "var(--stels-color-gray)"}}>
        <span>Copyright © 2024 Gliesereum Ukraine LLC</span>
      </footer>
    </div>
  );
};

export default App;

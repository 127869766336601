// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --stels-color-red: #ef4848;
    --stels-color-green: rgb(9, 249, 113);
    --stels-color-blue: #1a91f3;
    --stels-color-orange: #f99b09;
    --stels-color-black: #000000;
    --stels-color-white: #d9d9d9;
    --stels-color-gray-light: #838383;
    --stels-color-gray: #676767;
    --stels-color-gray-dark: #040404;
    --stels-color-gray-dark-hard: #101010;

    --stels-main-backgroud-color: var(--stels-color-black);
    --stels-main-backgroud-color-light: var(--stels-color-gray-dark);

    --stels-main-text-color: var(--stels-color-white);
    --stels-main-text-color-light: var(--stels-color-gray-light);

    --stels-utils-font: "Saira", sans-serif;
    --stels-utils-font-size: 16px;
    --stels-utils-font-size-small: 12px;
    --stels-utils-radius: 1em;
    --stels-utils-pm: 1em;

}
`, "",{"version":3,"sources":["webpack://./src/styles/global/variables.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,qCAAqC;IACrC,2BAA2B;IAC3B,6BAA6B;IAC7B,4BAA4B;IAC5B,4BAA4B;IAC5B,iCAAiC;IACjC,2BAA2B;IAC3B,gCAAgC;IAChC,qCAAqC;;IAErC,sDAAsD;IACtD,gEAAgE;;IAEhE,iDAAiD;IACjD,4DAA4D;;IAE5D,uCAAuC;IACvC,6BAA6B;IAC7B,mCAAmC;IACnC,yBAAyB;IACzB,qBAAqB;;AAEzB","sourcesContent":[":root {\n    --stels-color-red: #ef4848;\n    --stels-color-green: rgb(9, 249, 113);\n    --stels-color-blue: #1a91f3;\n    --stels-color-orange: #f99b09;\n    --stels-color-black: #000000;\n    --stels-color-white: #d9d9d9;\n    --stels-color-gray-light: #838383;\n    --stels-color-gray: #676767;\n    --stels-color-gray-dark: #040404;\n    --stels-color-gray-dark-hard: #101010;\n\n    --stels-main-backgroud-color: var(--stels-color-black);\n    --stels-main-backgroud-color-light: var(--stels-color-gray-dark);\n\n    --stels-main-text-color: var(--stels-color-white);\n    --stels-main-text-color-light: var(--stels-color-gray-light);\n\n    --stels-utils-font: \"Saira\", sans-serif;\n    --stels-utils-font-size: 16px;\n    --stels-utils-font-size-small: 12px;\n    --stels-utils-radius: 1em;\n    --stels-utils-pm: 1em;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

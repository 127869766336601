// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/font/Saira-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/font/Saira-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/font/Saira-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Saira';
    src: local('Saira'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-stretch: 100%;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: local('Saira'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-stretch: 100%;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src: local('Saira'), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-stretch: 100%;
    font-display: swap;
}
`, "",{"version":3,"sources":["webpack://./src/styles/global/fonts.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,+EAAkF;IAClF,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,+EAAiF;IACjF,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,+EAA+E;IAC/E,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: 'Saira';\n    src: local('Saira'), url('../../assets/font/Saira-Regular.ttf') format('truetype');\n    font-weight: 400;\n    font-style: normal;\n    font-stretch: 100%;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'Saira';\n    src: local('Saira'), url('../../assets/font/Saira-Medium.ttf') format('truetype');\n    font-weight: 500;\n    font-style: normal;\n    font-stretch: 100%;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'Saira';\n    src: local('Saira'), url('../../assets/font/Saira-Bold.ttf') format('truetype');\n    font-weight: 600;\n    font-style: normal;\n    font-stretch: 100%;\n    font-display: swap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

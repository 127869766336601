import React, {FC, useEffect} from 'react';
import "./Modal.style.css"
import {AnimatePresence, motion} from 'framer-motion';
import Graphite from "../Craphite/Graphite";

interface ModalProps {
  type: "mini" | "screen" | "full"
  isOpen: boolean;
  onClose?: (e: any) => void;
  children: any;
  raw?: any;
  title: string;
  subTitle: string;
  action?: (e: any) => void;
}

const Modal: FC<ModalProps> = ({type, isOpen, onClose, children, title, subTitle, action}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.className = "stels-modal-magic"
      document.body.style.transition = "all 0.2s ease-out"
      document.documentElement.style.background = "var(--stels-color-black)"
      document.documentElement.style.transition = "all 0.2s ease-in-out"
      document.documentElement.style.overflow = "hidden"
    }
    return () => {
      document.body.className = "stels-modal-magic-hidden"
      document.documentElement.style.overflow = "hidden"
      document.documentElement.style.background = "var(--stels-color-black)"
    }
  }, [isOpen]);

  const variants = {
    active: {
      y: 0,
      transition: {
        type: "string",
        damping: 90,
        stiffness: 1550,
        duration: 0.2
      }
    },
    inactive: {
      y: 1200,
      transition: {
        type: "string",
        damping: 180,
        stiffness: 950,
        duration: 0.3
      }
    },
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className={`${(type === "mini" ? "stels-modal-mini" : type === "full" ? "stels-modal-full" : "stels-modal")}`}
            key="modal"
            variants={variants}
            initial="inactive"
            animate="active"
            exit="inactive"
          >
            <header style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
              <div className="stels-modal-left-box">
                <div className="stels-modal-title-icon">
                  <Graphite size={2.4} primary={"var(--stels-color-green)"}/>
                </div>
                <div>
                  <div>
                    <span style={{fontSize: 20}}>
                      {title}
                    </span>
                  </div>
                  <div>
                    <span style={{fontSize: 12, color: 'var(--stels-color-gray)'}}>
                      {subTitle}
                    </span>
                  </div>
                </div>
              </div>
            </header>
            <main>
              {children}
            </main>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
};

export default Modal

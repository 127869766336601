// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.huragan-button {
    background: var(--stels-color-green);
    color: var(--stels-color-black);
    width: 100%;
    display: block;
    height: 50px;
    border-radius: 0.4em;
    text-align: center;
    font-weight: 500;
}
.huragan-button:disabled {
    background: var(--stels-color-gray);
    color: var(--stels-color-black);
}

`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.style.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,+BAA+B;IAC/B,WAAW;IACX,cAAc;IACd,YAAY;IACZ,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,mCAAmC;IACnC,+BAA+B;AACnC","sourcesContent":[".huragan-button {\n    background: var(--stels-color-green);\n    color: var(--stels-color-black);\n    width: 100%;\n    display: block;\n    height: 50px;\n    border-radius: 0.4em;\n    text-align: center;\n    font-weight: 500;\n}\n.huragan-button:disabled {\n    background: var(--stels-color-gray);\n    color: var(--stels-color-black);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

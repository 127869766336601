import {createRoot, Root} from "react-dom/client";

export default function createAppRoot(window: any): Root {
  try {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      window.document.addEventListener('wheel', (e: WheelEvent) => {
        if (e.deltaMode !== 1) {
          e.preventDefault();
        }
      }, {passive: false});
    }
    
    document.addEventListener('wheel', (event: WheelEvent) => {
      if (event.deltaMode > 1) {
        event.preventDefault();
      }
    }, false);
    const root = document.createElement('div');
    root.className = 'web';
    document.body.appendChild(root);
    return createRoot(root);
  } catch (e) {
    console.error(e);
    throw new Error("Failed to create React Root");
  }
}

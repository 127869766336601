import {motion} from "framer-motion";
import React from "react";
import "./Button.style.css"


const Button = (props: any) => {
  return (
    <motion.button
      className="huragan-button"
      whileTap={{scale: 0.98, opacity: 0.8}}
      {...props}
    />
  );
};

export default Button;
